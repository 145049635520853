export const testimonial_data = [
    { id: 1, image: require('../assets/testimonials/denis.jpg'), testimonial: '“Excellent work. Good customer support”', name: 'Denis K' },
    { id: 2, image: require('../assets/testimonials/MarkL.jpg'), testimonial: '"Very good Freelancer to work with. We had to make some changes along the way and Weblance accommodated those quickly and effortlessly. We also found a couple of things we had not initially been aware of from our old site but Weblance was able to incorporate the required changes easily in the new site and still all on time and on budget. Weblance was very patient with us as well when we needed to get further approval in our organisation to proceed further with the changes made."', name: 'Mark L' },
    { id: 3, image: require('../assets/testimonials/michaelH.jpg'), testimonial: '“This was my first website and first time working with Weblance. I am completely satisfied with the end result. They were easy to communicate with were obliging to make all changes requested. There was a good balance between being creative and poloishing off what i had already attempted to do in terms of content and imagery. Thank you.”', name: 'Michael H' },
    { id: 4, image: require('../assets/testimonials/Arthur.jpg'), testimonial: '“These guys are great! I use them for everything and they always produce in budget and on time.”', name: 'Arthur L' },
    { id: 5, image: require('../assets/testimonials/Murray.jpg'), testimonial: '“Always good to work with.”', name: 'Murray H' },
    { id: 6, image: require('../assets/testimonials/SueM.jpg'), testimonial: '“Weblance are awesome! High quality work, great communication and very helpful with edits. Cannot recommend them highly enough!!”', name: 'Sue M' },
    { id: 7, image: require('../assets/testimonials/ZackeryA.jpg'), testimonial: '"Weblance did a fantastic job. A huge props to Michael the recruiter for connecting us. Rupam (Weblance) was very flexible with changing requirements, acted professionally and provided high quality deliverables."', name: 'Zackery A' },
    { id: 8, image: require('../assets/testimonials/Sandro.jpg'), testimonial: '"Easy to work with. No issues. "', name: 'Sandro G' },
    { id: 9, image: require('../assets/testimonials/Dave.jpg'), testimonial: '"Great service and would recommend to anyone!!"', name: 'Dave B' },
    { id: 10, image: require('../assets/testimonials/Angela.jpg'), testimonial: '"Highly recommended. Very patient with me and my in-decisions. Great company to work with. "', name: 'Angela M' },
    { id: 11, image: require('../assets/testimonials/JocelynA.jpg'), testimonial: '"Weblance provided us with a Logo that was better than we expected. He captured the new business perfectly. We highly recommend Weblance."', name: 'Jocelyn A' },
    { id: 12, image: require('../assets/testimonials/JabirS.jpg'), testimonial: '"very professional and an expert in what he does"', name: 'Jabir S' },
    { id: 13, image: require('../assets/testimonials/RahulD.jpg'), testimonial: '"Professional team with good experience in website development "', name: 'Rahul D' },
    { id: 14, image: require('../assets/testimonials/John.jpg'), testimonial: '"very easy to work with. great communication and delivered great results. thank you!"', name: 'John J' },
    { id: 15, image: require('../assets/testimonials/Anton.jpg'), testimonial: '"good job and good attitude"', name: 'Anton S' },
    { id: 16, image: require('../assets/testimonials/SueM.jpg'), testimonial: '"Weblance are the by far the best website developers I have worked with on Freelancer. I have completed many projects with them and every time they deliver outstanding work. They provide great support with enhancing designs, answering questions and recommending improvements. Cannot recommend them highly enough!"', name: 'Sue M' },
    { id: 17, image: require('../assets/testimonials/Olamide.jpg'), testimonial: '"Did amazing, I can not wait to come back for help on getting my mobile app designed and created. "', name: 'Olamide A' },
    { id: 18, image: require('../assets/testimonials/Yun-Kit.jpg'), testimonial: '"Very responsible designer. Deliver on time, very good design, and good after-sales services. 5 stars were given in all aspects. @start-up in HK"', name: 'Yun Kit C' },
    { id: 19, image: require('../assets/testimonials/RamanaG.jpg'), testimonial: '"Good freelancer. Thank you for the support."', name: 'Ramana G' },
    { id: 20, image: require('../assets/testimonials/TammyC.jpg'), testimonial: '"Great work, from the brief was able to delivery exactly what we needed."', name: 'Tammy C' },
    { id: 21, image: require('../assets/testimonials/JDC.jpg'), testimonial: '“Very nice and professional, responded promptly and did great work.”', name: 'Jd C' },
    { id: 22, image: require('../assets/testimonials/Florian.jpg'), testimonial: '"This was my first project on Freelancer, and I am not only thrilled with the outcome but also with the way you supported me throughout the project. Thank you very much. I am happy to recommend you further."', name: 'Florian' },
]