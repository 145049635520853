import React from 'react';

const Error = () => {
    return (
        <>
            <h1>404 not found</h1>
        </>
    );
};

export default Error;