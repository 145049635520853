export const design_dev_data = [
    { id: 1, category: 'Wordpress Websites', url: 'wordpress-websites' },
    { id: 2, category: 'Woocommerce Websites', url: 'woocommerce-websites' },
    { id: 3, category: 'Shopify Websites', url: 'shopify-websites' },
    { id: 4, category: 'Php Websites', url: 'php-websites' },
    { id: 5, category: 'XD Templates', url: 'XD-templates' },
    { id: 6, category: 'Bootstrap Websites', url: 'bootstrap-websites' },
    { id: 7, category: 'Emailer Design', url: 'emailer-design' },
    { id: 8, category: 'Newsletter Design', url: 'newsletter-design' },
    { id: 9, category: 'E-catalogues', url: 'E-catalogues' },
    { id: 9, category: 'Top Websites', url: 'top-websites' },
    { id: 10, category: 'All Websites', url: 'all-websites' }
]

export const graphic_data = [
    { id: 1, category: 'Logo Design', url: 'logo-design' },
    { id: 2, category: 'Brochure Design', url: 'brochure-design' },
    { id: 3, category: 'Branding', url: 'branding' },
    { id: 4, category: 'Infograhic Design', url: 'infograhic-design' },
    { id: 5, category: 'Business Card', url: 'business-card' },
    { id: 6, category: 'Banner Design', url: 'banner-design' },
    { id: 7, category: 'Letter Heads', url: 'letter-heads' },
    { id: 8, category: 'Animation', url: 'animation' },
    { id: 9, category: 'Menu Design', url: 'menu-design' },
    { id: 10, category: 'Label Design', url: 'label-design' },
    { id: 11, category: 'Packaging Design', url: 'packaging-design' },
    { id: 12, category: 'E-book Cover', url: 'ebook-cover' },
    { id: 13, category: 'Standy Design', url: 'standy-design' },
    { id: 14, category: 'PPT Designs', url: 'PPT-designs' },
    { id: 15, category: 'Flyer', url: 'flyer' },
    { id: 16, category: 'Company Profile', url: 'company-profile' },
    { id: 17, category: 'T-shirt Design', url: 'Tshirt-design' }
]

export const content_data = [
    { id: 1, category: 'Article Writing', url: 'article-writing' },
    { id: 2, category: 'Business Writing', url: 'business-writing' },
    { id: 3, category: 'Product Descriptions', url: 'product-descriptions' },
    { id: 4, category: 'Website Content Writing', url: 'content-writing' },
    { id: 5, category: 'Blog Writing', url: 'blog-writing' },
    { id: 6, category: 'Essay Writing', url: 'essay-writing' },
    { id: 7, category: 'Proof Reading', url: 'proof-reading' }
]

export const seo_data = [
    { id: 1, category: 'Website Audit', url: 'website-audit' },
    { id: 2, category: 'Competitor Analyse', url: 'competitor-analyse' },
    { id: 3, category: 'Technical SEO', url: 'technical-seo' },
    { id: 4, category: 'Website Content', url: 'website-content' },
    { id: 5, category: 'Blog Posts', url: 'blog-posts' },
    { id: 6, category: 'Article Writing', url: 'article-writing' },
    { id: 7, category: 'SEO Writing', url: 'seo-writing' },
    { id: 8, category: 'Quality Backlinks', url: 'quality-backlinks' },
    { id: 9, category: 'Guest Posts', url: 'guest-posts' },
    { id: 10, category: 'Social Media Content', url: 'social-media-content' },
    { id: 11, category: 'Press Releases', url: 'press-releases' },
]

export const websites_data = [
    { id: 1, category: 'Corporations & Organizations', url: 'corporations-organizations' },
    { id: 2, category: 'Technology & Computing', url: 'technology-computing' },
    { id: 3, category: 'Education & Migration', url: 'education-migration' },
    { id: 4, category: 'Food & Restaurants', url: 'food-restaurants' },
    { id: 5, category: 'Skin & Beauty', url: 'skin-beauty' },
    { id: 6, category: 'Housing & Architecture', url: 'housing-architecture' },
    { id: 7, category: 'Online Stores', url: 'online-stores' },
    { id: 8, category: 'Law', url: 'law' },
    { id: 9, category: 'Casting/Modelling', url: 'casting-modelling' },
    { id: 10, category: 'Fitness', url: 'fitness' },
    { id: 11, category: 'Solar Services', url: 'solar-services' },
    { id: 12, category: 'Finance', url: 'finance' },
    { id: 13, category: 'Furniture', url: 'furniture' },
    { id: 14, category: 'Home Decor', url: 'home-decor' },
    { id: 15, category: 'Charity & Spiritual', url: 'charity-spiritual' },
    { id: 16, category: 'Consultants', url: 'consultants' },
    { id: 17, category: 'Interior Design', url: 'interior-design' },
    { id: 18, category: 'News', url: 'news' },
    { id: 19, category: 'Construction', url: 'construction' },
    { id: 20, category: 'Vehicles/ Automotive', url: 'vehicles-automotive' },
    { id: 21, category: 'Medical', url: 'medical' },
    { id: 22, category: 'Events and Wedding', url: 'events-wedding' },
    { id: 23, category: 'Logistics/ Transportation', url: 'logistics-transportation' },
    { id: 24, category: 'Cleaning', url: 'cleaning' },
    { id: 25, category: 'Kids', url: 'kids' },
    { id: 26, category: 'Travel', url: 'travel' },
    { id: 27, category: 'Job Portal', url: 'job-portal' },
    { id: 28, category: 'Electrical', url: 'electrical' },
    { id: 29, category: 'Repair', url: 'repair' },
    { id: 30, category: 'Casino', url: 'casino' },
]
