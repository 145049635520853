
export const client_data = [
    { id: 1, image: require('../assets/clients/client.png') },
    { id: 2, image: require('../assets/clients/client1.png') },
    { id: 3, image: require('../assets/clients/client2.png') },
    { id: 4, image: require('../assets/clients/client3.png') },
    { id: 5, image: require('../assets/clients/client4.png') },
    { id: 6, image: require('../assets/clients/client5.png') },
    { id: 7, image: require('../assets/clients/client6.png') },
    { id: 8, image: require('../assets/clients/client7.png') },
    { id: 9, image: require('../assets/clients/client8.png') },
    { id: 10, image: require('../assets/clients/client9.png') },
]

export const skills_data = [
    { id: 1, image: require('../assets/skills/adobe-illustrator.png') },
    { id: 2, image: require('../assets/skills/adobe-photoshop.png') },
    { id: 3, image: require('../assets/skills/adobe-xd.png') },
    { id: 4, image: require('../assets/skills/jquery.png') },
    { id: 5, image: require('../assets/skills/html5.png') },
    { id: 6, image: require('../assets/skills/css3.png') },
    { id: 7, image: require('../assets/skills/shopify.png') },
    { id: 8, image: require('../assets/skills/php.png') },
    { id: 9, image: require('../assets/skills/wordpress.png') },
    { id: 10, image: require('../assets/skills/figma.png') },
    { id: 11, image: require('../assets/skills/laravel.png') },
    { id: 12, image: require('../assets/skills/codeigniter.png') },
    { id: 13, image: require('../assets/skills/hubspot.png') },
    { id: 14, image: require('../assets/skills/CorelDRAW.png') },
    { id: 15, image: require('../assets/skills/woocommerce.png') },
    { id: 16, image: require('../assets/skills/Wix.png') },
    { id: 17, image: require('../assets/skills/Webflow.png') },
    { id: 18, image: require('../assets/skills/Squarespace.png') },
]

export const expertise_data = [
    { id: 1, image: require('../assets/expertise/adobe-photoshop.png') },
    { id: 2, image: require('../assets/expertise/adobe-xd.png') },
    { id: 3, image: require('../assets/expertise/figma.png') },
    { id: 4, image: require('../assets/expertise/adobe-illustrator.png') },
    { id: 5, image: require('../assets/expertise/coreldraw.png') },
    { id: 6, image: require('../assets/expertise/WordPress.png') },
    { id: 7, image: require('../assets/expertise/Shopify.png') },
    { id: 8, image: require('../assets/expertise/php.png') },
    { id: 9, image: require('../assets/expertise/codeigniter.png') },
    { id: 10, image: require('../assets/expertise/woocommerce.png') },
    { id: 11, image: require('../assets/expertise/html5.png') },
    { id: 12, image: require('../assets/expertise/MySQL.png') },
    { id: 13, image: require('../assets/expertise/Wix.png') },
    { id: 14, image: require('../assets/expertise/Squarespace.png') },
    { id: 15, image: require('../assets/expertise/webflow.png') },
]